import { Group, AppShell, Box, Badge, Tooltip } from '@mantine/core';
import Link from 'next/link';
import UserSettings from '../UserSettings/UserSettings';
import { observer } from 'mobx-react-lite';
import logo from '@/static/images/logo.svg';
import logoWideDark from '@/static/images/logo-wide-dark.svg';
import logoWideLight from '@/static/images/logo-wide-light.svg';
import classes from './Header.module.css';
import { useFinalColorScheme } from '@/hooks/mantine';
import { useMemo } from 'react';
import ExecutionsPopup from '../ExecutionsPopup/ExecutionsPopup';
import authState from '@/store/auth';
import { PiDetective } from '@react-icons/all-files/pi/PiDetective';

export const HEADER_HEIGHT = 60;

type HeaderProps = {
  height?: number;
  isMinimized?: boolean;
  width?: string | number;
};

function Header({
  height = HEADER_HEIGHT,
  width = '100%',
  isMinimized = false,
}: HeaderProps) {
  const colorScheme = useFinalColorScheme();
  // if (appShellState.isSidebarExpanded && !isNaN(width as any)) {
  //   (width as any) *= 2;
  // }

  const headerContent = useMemo(
    () => (
      <Group
        className={classes.wrapperGroup}
        pl={isMinimized ? 0 : 12}
        pr={isMinimized ? 0 : 8}
        justify="space-between"
      >
        <Link href="/" className={classes.brandHolder}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          {isMinimized ? (
            <img
              src={logo}
              alt="mockingjay.io Logo"
              className={classes.brandLogo}
            />
          ) : (
            <img
              src={colorScheme === 'light' ? logoWideLight : logoWideDark}
              alt="mockingjay.io Logo"
              className={classes.brandLogoWide}
            />
          )}
        </Link>
        <Group>
          {authState.assumeTeamRole && authState.selectedTeam && (
            <Tooltip
              label={`Viewing as ${authState.assumeTeamRole.toLowerCase()} `}
              withArrow
            >
              <Badge
                leftSection={<PiDetective />}
                radius="sm"
                variant="light"
                color="gray"
                tt="none"
              >
                {authState.selectedTeam.name}
              </Badge>
            </Tooltip>
          )}

          <ExecutionsPopup />
          <UserSettings />
        </Group>
      </Group>
    ),
    [colorScheme, isMinimized],
  );

  if (isMinimized) {
    return (
      <Box
        p="xs"
        className={classes.header}
        style={{
          width,
          height,
        }}
      >
        {headerContent}
      </Box>
    );
  }

  return (
    <AppShell.Header
      zIndex={150}
      p="xs"
      className={classes.header}
      style={{
        width,
        height,
      }}
    >
      {headerContent}
    </AppShell.Header>
  );
}

export default observer(Header);
