import {
  AnyVariables,
  Client,
  CombinedError,
  DocumentInput,
  OperationContext,
  fetchExchange,
  mapExchange,
  Operation,
  makeOperation,
} from 'urql';
import { cacheExchange, type Cache } from '@urql/exchange-graphcache';
import { retryExchange } from '@urql/exchange-retry';
import { devtoolsExchange } from '@urql/devtools';

import authState from './store/auth';
import config from './config';
import { gql } from './__generated__';
import type { InvalidateWorkaroundMutation } from './__generated__/graphql';
import { showNotification } from '@mantine/notifications';
import logger from '@/utils/logger';

const MAX_RETRIES = 3;

// Standard mutations whose invalidation is simple using __typename & id from response
// Example: The Test with ID which is deleted or updated is invalidated based on the response
const standardMutations = [
  'deleteApiKeysByPk',
  'deleteVariablesByPk',
  'deleteFoldersByPk',
  'deleteBlocksByPk',
  'deleteFunctionalitiesByPk',
  'deleteNotificationChannelsByPk',
  'deleteEnvironmentsByPk',
  'deleteEnvironmentsRolesByPk',
  'deleteSecurityScanFindingExclusionsByPk',
  'deleteApiEndpointsByPk',
  'updateEnvironmentsByPk',
  'updateEnvironmentsRolesByPk',
];

// Mutations whose invalidation are using id from args and the provided __typename from the array
// Example: The Test with ID which is deleted is invalidated based on the request args
const argIdMutations = [
  ['deleteTest', 'Tests'],
  ['deleteTestExecution', 'TestExecutions'],
];

// Mutations which have to result in invalidation of entire lists of entities denoted by the entities array
// Example: list of variables is invalidated when a new variable is created
const entityListMutations: [string, string[]][] = [
  ['insertVariablesOne', ['searchVariables']],
  ['insertBlocksOne', ['searchBlocks']],
  ['insertTeamsOne', ['teams']],
  ['insertEnvironmentsOne', ['environments']],
  ['insertEnvironmentsRolesOne', ['environments', 'environmentsRoles']],
  ['insertFunctionalitiesOne', ['functionalities']],
  ['insertNotificationChannelsOne', ['notificationChannels']],
  ['insertApiDocumentationsOne', ['apiDocumentations']],
  [
    'insertFoldersOne',
    ['folders', 'resolveFolderFromPath', 'getFolderAncestors'],
  ],
  ['updateApiModulesByPk', ['apiDocumentations']],
  ['insertApiEndpointsOne', ['apiDocumentations', 'apiEndpoints']],
  ['updateApiEndpointsByPk', ['apiDocumentations', 'apiEndpoints']],
  ['updateTestsByPk', ['testTags']],
  [
    'updateSecurityScanFindings',
    [
      'suiteExecutionsByPk',
      'suiteExecutions',
      'securityScanFindings',
      'securityScanResults',
    ],
  ],
  [
    'insertSecurityScanFindingExclusionsOne',
    [
      'suiteExecutionsByPk',
      'suiteExecutions',
      'securityScanFindings',
      'securityScanResults',
    ],
  ],
  [
    'updateSecurityScanFindingExclusionsByPk',
    [
      'suiteExecutionsByPk',
      'suiteExecutions',
      'securityScanFindings',
      'securityScanResults',
    ],
  ],
];

// Entities which do not have an id to create a key
const keylessEntities = [
  'ApiCallEntriesAggregateFields',
  'ApiCallEntriesAggregate',
  'TestExecutionsAggregateFields',
  'TestExecutionsAggregate',
  'TestsAggregateFields',
  'TestsAggregate',
  'VariablesAggregateFields',
  'VariablesAggregate',
  'TestsBlocks',
  'apiCallEntriesOverview',
  'ApiCallEntriesAvgFields',
  'apiCallEntriesDailyResponseTimes',
  'FoldersAggregateFields',
  'FoldersAggregate',
  'TestsBlocksAggregateFields',
  'TestsBlocksAggregate',
  'BlocksAggregateFields',
  'BlocksAggregate',
  'NotificationChannelsAggregateFields',
  'NotificationChannelsAggregate',
  'ApiKeysAggregateFields',
  'ApiKeysAggregate',
  'SuitesMetricsSummary',
  'SuitesAggregateFields',
  'SuitesAggregate',
  'TestExecutionsMaxFields',
  'TestsUrls',
  'VersionsAggregate',
  'VersionsAggregateFields',
  'SuiteExecutionsAggregate',
  'SuiteExecutionsAggregateFields',
  'SecurityScanResultsAggregate',
  'SecurityScanResultsAggregateFields',
  'SecurityScanRulesAggregate',
  'SecurityScanRulesAggregateFields',
  'securityScanResultsGroupedBySuites',
  'suiteExecutionStatistics',
  'TeamsAggregate',
  'TeamsAggregateFields',
  'EnvironmentsAggregate',
  'EnvironmentsAggregateFields',
  'TeamsUsersAggregate',
  'TeamsUsersAggregateFields',
  'SuiteExecutionsSummaryAggregate',
  'SuiteExecutionsSummaryAggregateFields',
];

/**
 * Invalidate all queries for the given entities.
 *
 * @param cache
 * @param entities
 */
function invalidateAllForEntities(cache: Cache, entities: string[]) {
  const key = 'Query';
  const fields = cache.inspectFields(key);
  for (const entity of entities) {
    fields
      .filter((field) =>
        field.fieldName.toLowerCase().startsWith(entity.toLowerCase()),
      )
      .forEach((field) => {
        cache.invalidate(key, field.fieldKey);
      });
  }
}

export const urqlClient = new Client({
  url: config.GRAPHQL_CDN_URL,
  requestPolicy: 'cache-and-network',
  exchanges: [
    devtoolsExchange,
    cacheExchange({
      keys: {
        ...keylessEntities.reduce(
          (acc, entity) => {
            acc[entity] = () => null;
            return acc;
          },
          {} as Record<string, any>,
        ),
        ApiCallEntries: (data) => {
          if (data.id) {
            return `${data.id}`;
          }
          return null;
        },
        TestTags: (data) => {
          if (data.tag) {
            return `${data.tag}`;
          }
          return null;
        },
        ApiModules: (data) => {
          return `${data.teamId}:${data.name}`;
        },
        FunctionalityExecutions: (data) => {
          return `${data.functionalityId}:${data.suiteExecutionId}`;
        },
      },
      updates: {
        Mutation: {
          updateVariablesByPk(result, args, cache, info) {
            // Invalidate searchVariables when folderId is changed
            // Since the search results can change depending on which folder the user is in
            const folderId = (args?._set as any)?.folderId;
            if (folderId || folderId === null) {
              invalidateAllForEntities(cache, ['searchVariables']);
            }
          },
          updateBlocksByPk(result, args, cache, info) {
            // Invalidate searchVariables when folderId is changed
            // Since the search results can change depending on which folder the user is in
            const folderId = (args?._set as any)?.folderId;
            if (folderId || folderId === null) {
              invalidateAllForEntities(cache, ['searchBlocks']);
            }
          },
          updateFoldersByPk(result, args, cache, info) {
            // Invalidate 'folders', 'resolveFolderFromPath', 'getFolderAncestors' when folderId is changed
            // Since the folder resolution results can change depending on which folder the user is in
            const parentId = (args?._set as any)?.parentId;
            if (parentId || parentId === null) {
              invalidateAllForEntities(cache, [
                'folders',
                'resolveFolderFromPath',
                'getFolderAncestors',
              ]);
            }
          },
          deleteApiEndpoints(result, args, cache, info) {
            if (!result.deleteApiEndpoints) {
              return;
            }
            for (const entity of (result.deleteApiEndpoints as any)
              .returning as any[]) {
              cache.invalidate(entity);
            }
          },
          deleteApiModulesByPk(result, args, cache, info) {
            cache.invalidate(result as any);
            invalidateAllForEntities(cache, ['apiDocumentations']);
          },
          // TODO: Remove this workaround once the issue is fixed in urql
          invalidateWorkaround(result, args, cache, info) {
            const { id: ids, entity: entities } = args.input as any;
            if (ids && typeof ids === 'string') {
              for (const id of ids.split(',')) {
                id &&
                  cache.invalidate({
                    __typename: entities,
                    id,
                  });
              }
            } else {
              invalidateAllForEntities(cache, entities.split(','));
            }
          },
          ...standardMutations.reduce(
            (acc, mutationName) => {
              acc[mutationName] = (
                result: any,
                args: any,
                cache: Cache,
                info: any,
              ) => {
                cache.invalidate((result as any)[mutationName]);
              };
              return acc;
            },
            {} as Record<string, any>,
          ),
          ...argIdMutations.reduce(
            (acc, [mutationName, entity]) => {
              acc[mutationName] = (
                result: any,
                args: any,
                cache: Cache,
                info: any,
              ) => {
                cache.invalidate({
                  __typename: entity,
                  id: args.id as any,
                });
              };
              return acc;
            },
            {} as Record<string, any>,
          ),
          ...entityListMutations.reduce(
            (acc, [mutationName, entities]) => {
              acc[mutationName] = (
                result: any,
                args: any,
                cache: Cache,
                info: any,
              ) => {
                invalidateAllForEntities(cache, entities);
              };
              return acc;
            },
            {} as Record<string, any>,
          ),
        },
      },
    }),
    retryExchange({
      maxNumberAttempts: MAX_RETRIES,
      retryWith(error, operation) {
        if (error.networkError) {
          const context = { ...operation.context, url: config.GRAPHQL_URL };
          return { ...operation, context };
        }
        return null;
      },
    }),
    mapExchange({
      onOperation(operation: Operation): Promise<Operation> | Operation | void {
        let headers: Record<string, string> = {
          ...(authState.axios.defaults.headers.common as any),
          'x-graphql-client-name': 'dashboard',
          'x-graphql-client-version': `${config.BUILD_ID}`,
        };

        if (authState.userHash) {
          headers['x-graphql-user-hash'] = authState.userHash;
        }

        const existingContext = operation.context;
        const existingFetchOptions = existingContext.fetchOptions
          ? typeof existingContext.fetchOptions === 'function'
            ? existingContext.fetchOptions()
            : existingContext.fetchOptions
          : {};

        // Remove teamId, teamAssumeRole & versionId headers for global & user-scoped queries
        if (existingContext.global || existingContext.user) {
          delete headers['x-team-id'];
          delete headers['x-version-id'];
          delete headers['x-team-assume-role'];

          if (existingContext.global) {
            headers['x-user-scope'] = 'global';
          }
        }

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            credentials: 'include',
            headers,
            ...existingFetchOptions,
          },
        });
      },
      onError(error: CombinedError, operation: Operation<any, AnyVariables>) {
        const retryCount = operation.context.retry?.count ?? 0;
        logger.error(
          { error, operation, retryCount },
          'GraphQL request failed',
        );
        if (error.networkError) {
          if (retryCount >= MAX_RETRIES - 1) {
            showNotification({
              color: 'red',
              message:
                'Unexpected error occurred during request. Please refresh & try again.',
            });
          }
        }
      },
    }),
    fetchExchange,
  ],
});

export class GraphQLError extends Error {
  errors: CombinedError['graphQLErrors'];
  constructor(errors: CombinedError['graphQLErrors']) {
    super();
    this.name = 'GraphQLError';
    this.errors = errors;
    const messages: string[] = [];
    for (const error of errors) {
      messages.push(error.message);
    }
    this.message = messages.join('\n');
  }
}

export async function executeMutation<
  Data = any,
  Variables extends AnyVariables = AnyVariables,
>(
  query: DocumentInput<Data, Variables>,
  variables: Variables,
  context?: Partial<OperationContext>,
): Promise<Data> {
  const res = await urqlClient.mutation(query, variables, context).toPromise();
  if (res.error && res.error.graphQLErrors) {
    throw new GraphQLError(res.error.graphQLErrors);
  }
  return res.data!;
}

export async function executeQuery<
  Data = any,
  Variables extends AnyVariables = AnyVariables,
>(
  query: DocumentInput<Data, Variables>,
  variables: Variables,
  context?: Partial<OperationContext>,
): Promise<Data> {
  const res = await urqlClient.query(query, variables, context).toPromise();
  if (res.error && res.error.graphQLErrors) {
    throw new GraphQLError(res.error.graphQLErrors);
  }
  return res.data!;
}

const invalidateWorkaroundMutation = gql(/* GraphQL */ `
  mutation InvalidateWorkaround($entity: String!, $id: String) {
    invalidateWorkaround(input: { entity: $entity, id: $id }) {
      status
    }
  }
`);

/**
 * Invalidate workaround.
 *
 * @param entity
 * @param id
 * @returns
 */
export async function invalidateWorkaround(
  entity: string,
  id?: string,
): Promise<InvalidateWorkaroundMutation> {
  return await executeMutation(invalidateWorkaroundMutation, {
    entity,
    id,
  });
}
