/**
 * Build URL Search params from a dictionary.
 *
 * @param data
 */
export function buildUrlSearchParams(
  data: Record<string, string>,
): URLSearchParams {
  const params = new URLSearchParams();
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      params.append(key, data[key]);
    }
  }
  return params;
}

/**
 * Build form data from the given data record.
 *
 * @param data
 */
export function buildFormData(
  data: Record<string, string | File | Blob>,
): FormData {
  const formData = new FormData();
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      formData.set(key, data[key]);
    }
  }
  return formData;
}
